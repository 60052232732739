h2{
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
	font-weight: 200;
}

nav.navbar{
	position:fixed;
	z-index:100;
}
nav .nav-background{
	top:64px;
	opacity: .7;
}

.pinned{
	position: static !important;
}

.nav-header h1{
	margin-top:64px;
}

#main{
	padding-top: 50px;
	padding-bottom: 55px;
	color: rgb(88, 88, 88);
}
.centering{
	margin:auto;
	display:block;
}

.nav-header{
	padding-top:80px !important;
}

.name{
	font-family: 'Sacramento', cursive;
	font-size:80px;
}
.name-exp{
	font-size:25px;
	font-weight:600;
}

#pronouns{
	font-size:20px;
	font-weight:500;
}

#name-first, #name-last{
	display: inline-block;
	margin-left:10px;
	margin-right:10px;
	text-align: center;
}

#name-container{
	display: block;
	margin: auto;
	text-align: center;
	padding-top:80px;
}

h2{
		font-size:50px;
}

p{
	font-size:16px;
}

.about-text-body{
	display: block;
	padding-left: 8%;
	padding-right: 8%;

}

.about-text-body p{
	color: rgb(88,88,88);
	font-size: 16px;
}

#headerSeattle{
	background-image: url('../img/seattle.jpg'); background-position:right center; background-size:cover;
}

#icons{
	text-align: center;
	margin-top:2em;
	letter-spacing: 10px;
}

#icons a, #sdg a{
	color: #b0bec5;
}

#icons a:hover, #sdg a:hover{
	color: #90a4ae;
}

#link-icons a{
	display: inline-block;
	line-height: 64px;
}

#sidenav-icons{
	margin-top:50px;
	letter-spacing: 8px;
	text-align: center;
}

#link-icons{
	margin-left:30px;
	margin-right:20px;
}

.sidenav{
	color:#000;
	background-color: #fff;
}

#sidenav-icons a{
	display: inline;
	padding:0px;
	line-height: auto;
	margin: 8px;
}

footer .container{
	text-align: center;
}

#sdg{
	margin-top:20px;
	text-align: center;
}

#sdg p{
	font-size: 15px;
	color: #999;
}

.sidenav li.active {
    background-color: rgba(0,0,0,0.07);
}

button {
    background: none;
    border: 0;
}

button:focus, button:active {
    background: none;
}

.carousel-wrapper .carousel-item {
    opacity: .8 !important;
    width: 300px !important;
    height: 200px;
}

.carousel{
	height: 250px;
}

#cornell-sq{
	max-width: 100%;
	opacity: .9;
}

#cornell-wide{
	display: none;
}

.about-text-body a{
	color:#9cadc9;
	font-weight: 500;
}

.about-text-body a:hover{
	color:#78909c;
}

#ada{
	max-width: 100%;
	width:700px;
	margin-top: 30px;
	margin-bottom:30px;
}


@media only screen and (max-width: 992px) {
	#school-blurb .col{
		padding:0px !important;
	}
	#cornell-sq{
		display: none;
	}
	#cornell-wide{
		display: block;
		max-width: 100%;
		opacity: .9;
	}

}

@media only screen and (min-width: 860px) {
	#about-carousel-wrapper{
		padding-left: 8%;
		padding-right: 8%;
	}
}

@media only screen and (max-width: 600px) {
	.name{
		font-size:54px;
	}
	.name-exp{
		font-size: 20px;
	}
	h2{
		font-size: 35px;
	}
	#headerSeattle{
		background-position:48% center;
	}
}
