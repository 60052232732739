/**** TYPOGRAPHY ****/
h1, h2, h3, h4, h5 .h1, .h2, .h3, .h4, .h5 {
  font-weight: 200;
}

h1 {
  margin-bottom: 50px;
}

h5 {
  font-size: .9rem;
  font-weight: 500;
  text-transform: uppercase;
}

p {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.8rem;
  color: rgba(255, 255, 255, 0.8);
}

p.caption {
  color: inherit;
}

.feature {
  margin-bottom: 50px;
}

span.date {
  text-transform: uppercase;
  font-weight: 300;
}

nav.navbar ul.dropdown-content li > a::before,
nav.navbar ul.dropdown-content li > span::before,
.dropdown-content li > a::before,
.dropdown-content li > span::before, .sidenav li > a::before, nav.navbar ul a::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: background-color .2s, -webkit-transform .2s;
  transition: transform .2s, background-color .2s;
  transition: transform .2s, background-color .2s, -webkit-transform .2s;
}

nav.navbar ul.dropdown-content li > a::before,
nav.navbar ul.dropdown-content li > span::before,
.dropdown-content li > a::before,
.dropdown-content li > span::before, .sidenav li > a::before {
  background-color: #26a69a;
  left: -10px;
  width: 2px;
  height: 100%;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}


/**** HEADER ****/
.header {
  color: #fff;
}

.header .header-wrapper {
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.header .header-wrapper h1 {
  margin: 1rem 0;
  font-weight: 600;
  font-size: 6rem;
  text-transform: uppercase;
}

.header .header-wrapper .tagline {
  display: block;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 200;
}

.header .header-wrapper .read-more {
  height: 64px;
  line-height: 64px;
  transition: color .2s;
}

.header .header-wrapper .read-more:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  color: #fff;
}

.header .header-wrapper .read-more i {
  font-size: 24px;
}

#scrolling-devices-intro {
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: #fff;
}

#scrolling-devices-intro .header-wrapper .valign-wrapper {
  height: 100%;
}

#scrolling-devices-intro .header-wrapper .valign-wrapper .valign {
  margin: 0 auto;
}

#scrolling-devices-intro .column-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#scrolling-devices-intro .column-wrapper .column-one,
#scrolling-devices-intro .column-wrapper .column-two {
  position: relative;
}

#scrolling-devices-intro .column-wrapper .column-one {
  margin-top: -100px;
}

#scrolling-devices-intro .column-wrapper .column-two {
  margin-top: -250px;
}

#scrolling-devices-intro .phone-preview-sizer {
  width: 150px;
  margin-bottom: 20px;
  margin-right: 20px;
}

#implode-intro {
  position: relative;
  height: 200%;
}

#implode-intro .devices-wrapper {
  height: 50%;
}

#implode-intro .desktop-preview-sizer,
#implode-intro .laptop-preview-sizer,
#implode-intro .phone-preview-sizer {
  position: absolute;
}

#implode-intro .desktop-preview-sizer {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 300px;
}

#implode-intro .laptop-preview-sizer {
  top: -1000px;
  left: calc(((100% - 400px) / 2) - 100px);
  width: 250px;
}

#implode-intro .laptop-preview-sizer.scrollmagic-end {
  top: calc(50% - 10px);
}

#implode-intro .tablet-preview-sizer {
  top: 50%;
  left: -1000px;
  width: 100px;
}

#implode-intro .tablet-preview-sizer.scrollmagic-end {
  left: calc(((100% - 400px) / 2) - 200px);
}

#implode-intro .phone-preview-sizer {
  right: -1000px;
  width: 50px;
}

#implode-intro .phone-preview-sizer.scrollmagic-end {
  right: calc(((100% - 400px) / 2) - 100px);
}

@font-face {
  font-family: 'simple-line-icons';
  src: url("../fonts/simple-line-icons/simple-line-icons.eot?2k1x4j");
  src: url("../fonts/simple-line-icons/simple-line-icons.eot?2k1x4j#iefix") format("embedded-opentype"), url("../fonts/simple-line-icons/simple-line-icons.ttf?2k1x4j") format("truetype"), url("../fonts/simple-line-icons/simple-line-icons.woff?2k1x4j") format("woff"), url("../fonts/simple-line-icons/simple-line-icons.svg?2k1x4j#simple-line-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caret-down:before {
  content: "\e0a2";
}

.header .header-wrapper .read-more {
    height: 64px;
    line-height: 64px;
    transition: color .2s;
}
button {
    background: none;
    border: 0;
}

button:focus, button:active {
    background: none;
}

a:focus, a:active{
	border: 0;
	outline: none;
}


.section.light h1{
	color: #000;
}

#pagelinks h2{
	color:gray;
}

#icons{
	text-align: center;
	margin-top:2em;
	letter-spacing: 10px;
}

#icons a, #sidenav-icons a, #sdg a{
	color: #b0bec5;
}

#icons a:hover, #sidenav-icons a:hover, #sdg a:hover{
	color: #90a4ae;
}

nav.nav-extended {
  height: 400px;
}

#down2{
	color: white !important;
}

nav{
	font-family: 'Lato', sans-serif;
}

.tagline strong{
	font-size: 35px;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
	font-weight:300;
}

#subtag-intro{
	font-size:20px;
	font-weight:300;
	color:grey;
	font-family: 'Lato', sans-serif;
}

#subtag-second{
	font-size:30px;
	font-weight:300;
}

.material-tooltip{padding:10px 8px;font-size:1rem;z-index:2000;background-color:transparent;border-radius:2px;color:#fff;min-height:36px;line-height:120%;opacity:0;position:absolute;text-align:center;max-width:calc(100% - 4px);overflow:hidden;left:0;top:0;pointer-events:none;visibility:hidden;background-color:#323232}

i {
    color: inherit;
}

.centered{
	text-align: center;
	display: block;
	margin: auto;
}


.collage{
	background-color:white;
	background-image:url("../img/collage.jpg");
	background-size: 65% 45%;
	background-position:center center;
}

@media screen and (max-width:800px){
	.pink{
		background-size: cover;
	}
}

.csoon{
		display: inline-block;
    vertical-align: middle;
    float: none !important;
}
.narrow{
	max-width: 900px;
    margin: 0 auto;
    padding: 0 5%;
	}

.page-footer{
	background-color: #eee !important;
}

.categs{
	font-family: 'Montserrat', sans-serif;
	font-weight: bolder;
	font-size: 40px;
}

.blurb{
	font-family: 'Lato', sans-serif;
	font-size: 22px;
	font-weight: 300;
	color: rgba(0,0,0,0.87);
}

nav.solid{
	background-color: #fff !important;
}

nav.navbar.solid :not(.dropdown-content)>li>a, nav.navbar.solid .brand-logo i{
	color: #000 !important;
}
nav.navbar.solid ul:not(.indicators) li.active::before, nav.navbar.solid a::before{
	background-color: #000;
}

.section.light{
	background-color: #f4f4f4;
}

.sidenav{
	background-color: #fff !important;
}

.sidenav li{
	color:#000;
}

.sidenav li > a {
    color: rgba(0, 0, 0, 0.87);
    display: block;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    line-height: 48px;
    padding: 0 32px;
		font-family: "Lato", sans-serif;
		font-weight: 300;
}

nav.navbar ul.dropdown-content li>a::before, nav.navbar ul.dropdown-content li>span::before, .dropdown-content li>a::before, .dropdown-content li>span::before, .sidenav li>a::before {
    background-color: #aaa;
}

#link-icons a{
	display: inline-block;
	line-height: 64px;
}

#sidenav-icons{
	margin-top:50px;
	letter-spacing: 8px;
	text-align: center;
}

#link-icons{
	margin-left:30px;
	margin-right:20px;
}

#sdg{
	margin-top:20px;
	text-align: center;
}

#sdg p{
	font-size: 15px;
}

#where-now, #where-prev{
	display: inline-block;
	vertical-align: top;
}

.where{
	margin-top:20px;
}

.where li a{
	color:#979797;
	font-family: 'Lato', sans-serif;
}

.where li a:hover{
	color:black;
}

.where-header{
	display: block;
	margin-bottom:-10px;
	font-weight: 500;
	color: rgb(151, 151, 151);
	margin-top:50px;
	font-family: 'Lato', sans-serif;
}

#where-container{
	color:black !important;
}

#welcome-container{
	overflow: hidden;
	text-align: center;
          justify-content: center;
}

#welcome-horiz{
	display: none;
}

nav.dark{
	background-color: white !important;
}

#circle3{
	background-color: black;
	color: white;
}

/*****************
 * CIRCLE REVEAL *
 *****************/
.circle-reveal-intro {
  position: relative;
  height: auto;
  overflow: hidden;
}

.circle-reveal-intro[data-disabled="true"] .circle-reveal-wrapper,
.circle-reveal-intro[data-disabled="true"] .fixed-wrapper {
  position: relative;
}

.circle-reveal-intro[data-disabled="true"] .fixed-wrapper {
  height: auto;
}

.circle-reveal-intro[data-disabled="true"] .circle-background {
  height: 100%;
  width: 100%;
  border-radius: 0;
}

.circle-reveal-intro .circle-reveal-wrapper,
.circle-reveal-intro .fixed-wrapper {
  height: 100vh;
  width: 100%;
}

.circle-reveal-intro .background,
.circle-reveal-intro .header-wrapper {
  position: absolute;
  height: 100%;
}

.circle-reveal-intro .fixed-wrapper {
  position: absolute;
}

.circle-reveal-intro .circle-reveal-wrapper {
  position: absolute;
}

.circle-reveal-intro .circle-reveal-wrapper:first-child {
  z-index: 9;
}

.circle-reveal-intro .circle-reveal-wrapper .valign-wrapper {
  height: 100%;
}

.circle-reveal-intro .background {
  overflow: hidden;
}

.circle-reveal-intro .background img {
  width: 100%;
}

.circle-reveal-intro .circle-background {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 141.42vw;
  height: 141.42vw;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
  z-index: -1;
}

.circle-reveal-intro .header-wrapper {
  z-index: 3;
}

.circle-reveal-intro .background-layer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
  z-index: -1;
}

.circle-reveal-intro .background-layer.end {
  z-index: auto;
}

/* Portrait */
@media screen and (orientation: portrait) {
  .circle-reveal-wrapper .circle-background {
    width: 141.42vh;
    height: 141.42vh;
  }
}

/*********************
  Transition Classes
**********************/
ul.staggered-list li {
  opacity: 0;
}

.fade-in {
  opacity: 0;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
}



@media screen and (max-width: 600px){
	#welcome-container{
		max-height:300px;
	}
	#welcome-vert{
		display: none;
	}
	#welcome-horiz{
		display: block;
		object-fit: contain;
    width: 90%;
    margin-bottom:20px;
	}
  #welcome-horiz img{
    max-width: 90%;
  }
  .read-more, #circle2, #circle3{
    display: none !important;
  }
  .card{
    max-width:90%;
    display: block;
    margin:auto !important;
    margin-bottom:15px !important;
  }
  #subtag-intro{
    font-size:18px;
  }
  .circle-reveal-intro{
    overflow:visible;
  }
  .circle-reveal-intro .circle-reveal-wrapper, .circle-reveal-intro .fixed-wrapper{
    height: 820px;
  }
  .tagline h2{
    margin-top:0px;
  }
  #intro-container{
    margin-top:0px;
  }
}

@media screen and (max-width: 992px){
	#welcome-container{
		height:300px;
	}
	#welcome-vert{
		display: none;
	}
	#welcome-horiz{
		display: block;
		object-fit: contain;
	}
  #intro-container{
    margin-top:64px;
  }
}

@media only screen and (min-width: 601px){
  .row .col.m8 {
      width: 66.6666666667%;
      margin-left: auto;
      margin-right: auto;
      left: auto;
      right: auto;
  }
  .row .col.m10 {
      width: 83.3333333333%;
      margin-left: auto;
      margin-right: auto;
      left: auto;
      right: auto;
  }
}
