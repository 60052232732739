.portrait{
	width:300px;
}

#placeholder-navbar .back-btn {
	color:#fff;
	font-family: 'Lato', sans-serif;
}

/* This is needed for some mobile phones to display the Google Icon font properly */
.material-icons {
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
     -moz-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}

.navbar-fixed {
  position: relative;
  height: 56px;
  z-index: 997;
}

.navbar-fixed nav {
  position: fixed;
}

@media only screen and (min-width: 601px) {
  nav.nav-extended .nav-wrapper {
    min-height: 64px;
  }
  nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
    height: 64px;
    line-height: 64px;
  }
  .navbar-fixed {
    height: 64px;
  }
}

nav.navbar ul.dropdown-content li > a::before,
nav.navbar ul.dropdown-content li > span::before,
.dropdown-content li > a::before,
.dropdown-content li > span::before, .side-nav li > a::before, nav.navbar ul a::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: background-color .2s, -webkit-transform .2s;
  transition: transform .2s, background-color .2s;
  transition: transform .2s, background-color .2s, -webkit-transform .2s;
}

nav.navbar ul.dropdown-content li > a::before,
nav.navbar ul.dropdown-content li > span::before,
.dropdown-content li > a::before,
.dropdown-content li > span::before, .side-nav li > a::before {
  background-color: #aaa;
  left: -10px;
  width: 2px;
  height: 100%;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

/**** DROPDOWN ****/
nav.navbar ul.dropdown-content li:hover,
.dropdown-content li:hover {
  background-color: transparent;
}

nav.navbar ul.dropdown-content li > a,
nav.navbar ul.dropdown-content li > span,
.dropdown-content li > a,
.dropdown-content li > span {
  color: #444;
  padding-left: 0;
  padding-right: 0;
  transition: color .2s;
}

nav.navbar ul.dropdown-content li > a:hover,
nav.navbar ul.dropdown-content li > span:hover,
.dropdown-content li > a:hover,
.dropdown-content li > span:hover {
  color: #5c6bc0;
}

nav.navbar ul.dropdown-content li > a:hover::before,
nav.navbar ul.dropdown-content li > span:hover::before,
.dropdown-content li > a:hover::before,
.dropdown-content li > span:hover::before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.dropdown-button i.right {
  margin-left: 5px;
}

/**** NAVBAR ****/
nav.navbar {
  z-index: 10;
  position: fixed;
  box-shadow: none;
  background-color: transparent;
  transition: box-shadow .3s, background-color .3s;
}

nav.navbar.pinned {
  background-color: rgba(0, 0, 0, 0.3);
}

nav.navbar.absolute {
  position: absolute;
  top: 0;
  width: 100%;
}

nav.navbar.dark a, nav.navbar.solid a {
  color: #444;
}

nav.navbar.dark .brand-logo i, nav.navbar.solid .brand-logo i {
  color: #444;
}

nav.navbar.dark ul.dropdown-content li > a::before, nav.navbar.solid ul.dropdown-content li > a::before,
nav.navbar.dark ul.dropdown-content li > span::before, nav.navbar.solid ul.dropdown-content li > span::before,
nav.navbar.dark a::before, nav.navbar.solid a::before {
  background-color: #444;
}

nav.navbar.solid {
  background-color: #fff;
}

nav.navbar.solid.dark, nav.navbar.solid {
  background-color: #2b387c;
}

nav.navbar.solid.dark :not(.dropdown-content) > li > a, nav.navbar.solid :not(.dropdown-content) > li > a {
  color: #fff;
}

nav.navbar.solid.dark .brand-logo i, nav.navbar.solid .brand-logo i {
  color: #fff;
}

nav.navbar.solid.dark a::before, nav.navbar.solid a::before {
  background-color: #000;
}

nav.navbar.solid.dark ul.dropdown-content li > a::before, nav.navbar.solid ul.dropdown-content li > a::before,
nav.navbar.solid.dark ul.dropdown-content li > span::before, nav.navbar.solid ul.dropdown-content li > span::before {
  background-color: #2b387c;
}

nav.navbar .brand-logo {
  left: 15px;
  -webkit-transform: initial;
          transform: initial;
}

nav.navbar .brand-logo i {
  line-height: inherit;
  color: #fff;
  transition: color .2s;
}

nav.navbar.pinned .brand-logo i {
  color: #3f51b5;
}

nav.navbar ul > li.active > a::before,
nav.navbar ul > li > a.active::before,
nav.navbar ul > li > a:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

nav.navbar ul li.active {
  background-color: transparent;
}

nav.navbar ul a {
  padding-left: 0;
  padding-right: 0;
  margin: 0 10px;
  position: relative;
  font-weight: 300;
  transition: color .2s;
}

nav.navbar ul a:hover {
  background-color: transparent;
}

/**** SIDENAV ****/
.side-nav {
  background-color: #111;
}

.side-nav li.active > a,
.side-nav li:hover > a,
.side-nav li > a:hover,
.side-nav li > a.active {
  background-color: transparent;
}

.side-nav li.active > a::before,
.side-nav li:hover > a::before,
.side-nav li > a:hover::before,
.side-nav li > a.active::before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.side-nav li > a {
  position: relative;
  color: #eee;
  font-weight: 300;
  padding: 0 16px;
}

.side-nav li > a::before {
  left: auto;
  right: 0;
}

.side-nav .collapsible-body {
  background-color: #111;
}

/***************
    Nav List
***************/
.table-of-contents.fixed {
  position: fixed;
}

.table-of-contents li {
  padding: 2px 0;
}

.table-of-contents a {
  display: inline-block;
  font-weight: 300;
  color: #757575;
  padding-left: 20px;
  height: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: .4;
  display: inline-block;
}

.table-of-contents a:hover {
  color: #a8a8a8;
  padding-left: 19px;
  border-left: 1px solid #3f51b5;
}

.table-of-contents a.active {
  font-weight: 500;
  padding-left: 18px;
  border-left: 2px solid #3f51b5;
}

.side-nav {
  position: fixed;
  width: 300px;
  left: 0;
  top: 0;
  margin: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  height: 100%;
  height: calc(100% + 60px);
  height: -moz-calc(100%);
  padding-bottom: 60px;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateX(-105%);
          transform: translateX(-105%);
}

.side-nav.right-aligned {
  right: 0;
  -webkit-transform: translateX(105%);
          transform: translateX(105%);
  left: auto;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.side-nav .collapsible {
  margin: 0;
}

.side-nav li {
  float: none;
  line-height: 48px;
}

.side-nav li.active {
  background-color: rgba(0, 0, 0, 0.05);
}

.side-nav li > a {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 300;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
	font-family:'Lato',sans-serif;
}

.side-nav li > a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.side-nav li > a.btn, .side-nav li > a.btn-large, .side-nav li > a.btn-large, .side-nav li > a.btn-flat, .side-nav li > a.btn-floating {
  margin: 10px 15px;
}

.side-nav li > a.btn, .side-nav li > a.btn-large, .side-nav li > a.btn-large, .side-nav li > a.btn-floating {
  color: #fff;
}

.side-nav li > a.btn-flat {
  color: #343434;
}

.side-nav li > a.btn:hover, .side-nav li > a.btn-large:hover, .side-nav li > a.btn-large:hover {
  background-color: #6e7cc7;
}

.side-nav li > a.btn-floating:hover {
  background-color: #5c6bc0;
}

.side-nav li > a > i,
.side-nav li > a > [class^="mdi-"], .side-nav li > a li > a > [class*="mdi-"],
.side-nav li > a > i.material-icons {
  float: left;
  height: 48px;
  line-height: 48px;
  margin: 0 32px 0 0;
  width: 24px;
  color: rgba(0, 0, 0, 0.54);
}

.side-nav .divider {
  margin: 8px 0 0 0;
}

.side-nav .subheader {
  cursor: initial;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
}

.side-nav .subheader:hover {
  background-color: transparent;
}

.side-nav .userView {
  position: relative;
  padding: 32px 32px 0;
  margin-bottom: 8px;
}

.side-nav .userView > a {
  height: auto;
  padding: 0;
}

.side-nav .userView > a:hover {
  background-color: transparent;
}

.side-nav .userView .background {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.side-nav .userView .circle, .side-nav .userView .avatar-wrapper .avatar, .avatar-wrapper .side-nav .userView .avatar, .side-nav .userView .name, .side-nav .userView .email {
  display: block;
}

.side-nav .userView .circle, .side-nav .userView .avatar-wrapper .avatar, .avatar-wrapper .side-nav .userView .avatar {
  height: 64px;
  width: 64px;
}

.side-nav .userView .name,
.side-nav .userView .email {
  font-size: 14px;
  line-height: 24px;
}

.side-nav .userView .name {
  margin-top: 16px;
  font-weight: 500;
}

.side-nav .userView .email {
  padding-bottom: 16px;
  font-weight: 400;
}

.drag-target {
  height: 100%;
  width: 10px;
  position: fixed;
  top: 0;
  z-index: 998;
}

.side-nav.fixed {
  left: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  position: fixed;
}

.side-nav.fixed.right-aligned {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 992px) {
  .side-nav.fixed {
    -webkit-transform: translateX(-105%);
            transform: translateX(-105%);
  }
  .side-nav.fixed.right-aligned {
    -webkit-transform: translateX(105%);
            transform: translateX(105%);
  }
  .side-nav a {
    padding: 0 16px;
  }
  .side-nav .userView {
    padding: 16px 16px 0;
  }
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active,
.side-nav.fixed .collapsible-body > ul:not(.collapsible) > li.active {
  background-color: #3f51b5;
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active a,
.side-nav.fixed .collapsible-body > ul:not(.collapsible) > li.active a {
  color: #fff;
}

.side-nav .collapsible-body {
  padding: 0;
}

#sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  will-change: opacity;
}

nav {
  color: #fff;
  background-color: transparent;
  width: 100%;
  height: 64px;
  line-height: 64px;
	box-shadow: none;
}

nav.nav-extended .nav-wrapper {
  min-height: 64px;
  height: auto;
}

nav.nav-extended .nav-content {
  position: relative;
  line-height: normal;
}

nav a {
  color: #fff;
}

nav i,
nav [class^="mdi-"], nav [class*="mdi-"],
nav i.material-icons {
  display: block;
  font-size: 24px;
  height: 56px;
  line-height: 56px;
}

nav .nav-wrapper {
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 993px) {
  nav a.button-collapse {
    display: none;
  }
}

nav .button-collapse {
  float: left;
  position: relative;
  z-index: 1;
  height: 56px;
  margin: 0 18px;
}

nav .button-collapse i {
  height: 56px;
  line-height: 56px;
}

nav .brand-logo {
  position: absolute;
  color: #fff;
  display: inline-block;
  font-size: 2.1rem;
  padding: 0;
  white-space: nowrap;
}

nav .brand-logo.center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (max-width: 992px) {
  nav .brand-logo {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  nav .brand-logo.left, nav .brand-logo.right {
    padding: 0;
    -webkit-transform: none;
            transform: none;
  }
  nav .brand-logo.left {
    left: 0.5rem;
  }
  nav .brand-logo.right {
    right: 0.5rem;
    left: auto;
  }
}

nav .brand-logo.right {
  right: 0.5rem;
  padding: 0;
}

nav .brand-logo i,
nav .brand-logo [class^="mdi-"], nav .brand-logo [class*="mdi-"],
nav .brand-logo i.material-icons {
  float: left;
  margin-right: 15px;
}

nav .nav-title {
  display: inline-block;
  font-size: 32px;
  padding: 28px 0;
}

nav ul {
  margin: 0;
}

nav ul li {
  transition: background-color .3s;
  float: left;
  padding: 0;
}

nav ul li.active {
  background-color: rgba(0, 0, 0, 0.1);
}

nav ul a {
  transition: background-color .3s;
  font-size: 1rem;
  color: #fff;
  display: block;
  padding: 0 15px;
  cursor: pointer;
}

nav ul a.btn, nav ul a.btn-large, nav ul a.btn-large, nav ul a.btn-flat, nav ul a.btn-floating {
  margin-top: -2px;
  margin-left: 15px;
  margin-right: 15px;
}

nav ul a.btn > .material-icons, nav ul a.btn-large > .material-icons, nav ul a.btn-large > .material-icons, nav ul a.btn-flat > .material-icons, nav ul a.btn-floating > .material-icons {
  height: inherit;
  line-height: inherit;
}

nav ul a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

nav ul.left {
  float: left;
}

nav form {
  height: 100%;
}

nav .input-field {
  margin: 0;
  height: 100%;
}

nav .input-field input {
  height: 100%;
  font-size: 1.2rem;
  border: none;
  padding-left: 2rem;
}

nav .input-field input:focus, nav .input-field input[type=text]:valid, nav .input-field input[type=password]:valid, nav .input-field input[type=email]:valid, nav .input-field input[type=url]:valid, nav .input-field input[type=date]:valid {
  border: none;
  box-shadow: none;
}

nav .input-field label {
  top: 0;
  left: 0;
}

nav .input-field label i {
  color: rgba(255, 255, 255, 0.7);
  transition: color .3s;
}

nav .input-field label.active i {
  color: #fff;
}

.navbar-fixed {
  position: relative;
  height: 56px;
  z-index: 997;
}

.navbar-fixed nav {
  position: fixed;
}

@media only screen and (min-width: 601px) {
  nav.nav-extended .nav-wrapper {
    min-height: 64px;
  }
  nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
    height: 64px;
    line-height: 64px;
  }
  .navbar-fixed {
    height: 64px;
  }
}

nav.navbar{
	position:fixed;
	z-index:100;
}
nav .nav-background{
	top:64px;
}
nav .categories-wrapper, .nav-header h1{
	margin-top:64px;
}

@media screen and (max-width:600px){
	nav .categories-wrapper, .nav-header h1{
		margin-top:56px;
	}
}

.nav-header h1{
	font-family: 'Montserrat', serif;
	letter-spacing:1px;
}

.pinned{
	position: static !important;
}
#portfolio{
	padding-top:180px;
}
#categories-fixed{
	position:fixed;
	display:block;
	width:100%;
}
.centering{
	margin:auto;
	display:block;
	text-align: center;
}

@font-face {
  font-family: 'simple-line-icons';
  src: url("../fonts/simple-line-icons/simple-line-icons.eot?2k1x4j");
  src: url("../fonts/simple-line-icons/simple-line-icons.eot?2k1x4j#iefix") format("embedded-opentype"), url("../fonts/simple-line-icons/simple-line-icons.ttf?2k1x4j") format("truetype"), url("../fonts/simple-line-icons/simple-line-icons.woff?2k1x4j") format("woff"), url("../fonts/simple-line-icons/simple-line-icons.svg?2k1x4j#simple-line-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subtitle-proj{
	font-size:14px;
	color:#aaa;
}

.icon-home:before {
  content: "\e069";
}

nav.navbar.solid :not(.dropdown-content)>li>a, nav.navbar.solid .brand-logo i{
	color: #000 !important;
}
nav ul:not(.indicators) li.active::before, nav.navbar.solid a::before{
	background-color: #000;
}

.categories ::before{
	background-color: #fff !important;
}

nav.solid{
	background-color: #fff !important;
}

nav .nav-background .pattern {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
		background-color: #fff !important;
}

nav .categories-wrapper {
	background-color: #cfd8dc;
}
nav .nav-background {
    opacity: .4;
}

#icons{
	text-align: center;
	margin-top:2em;
}

#icons a, #sidenav-icons a, #sdg a{
	color: #b0bec5;
}

#icons a:hover, #sidenav-icons a:hover, #sdg a:hover{
	color: #90a4ae;
}

#link-icons a{
	display: inline-block;
	line-height: 64px;
}

#sidenav-icons{
	margin-top:50px;
	letter-spacing: 8px;
	text-align: center;
}

#link-icons{
	margin-left:30px;
	margin-right:20px;
}

nav.navbar ul.dropdown-content li>a::before, nav.navbar ul.dropdown-content li>span::before, .dropdown-content li>a::before, .dropdown-content li>span::before, .sidenav li>a::before {
    background-color: #aaa;
}

.sidenav li > a {
    color: rgba(0, 0, 0, 0.87);
    display: block;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    line-height: 48px;
    padding: 0 32px;
		font-family: "Lato", sans-serif;
		font-weight: 300;
}


.sidenav li.active {
    background-color: rgba(0,0,0,0.07);
}

.description{
  color: rgba(88,88,88);
  font-size: 22px;
  line-height: 33px;
}

footer .container{
	text-align: center;
}

.card{
	box-shadow: none;
}

.tab a{
	color:#993333 !important;
	text-decoration-color:#993333 !important;
}

.tabs .indicator{
	background-color:#993333 !important;
}

.tabs .tab a:hover,.tabs .tab a.active {
	background-color:transparent !important;
}

.description a{
	color:#9cadc9;
	font-weight: 500;
}

.description a:hover{
	color:#78909c;
}

@media screen and (max-width: 600px){
	.description{
		font-size: 18px !important;
		line-height: 27px !important;
	}
}
