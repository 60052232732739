/**** FOOTER ****/
.page-footer {
  color: #444;
  padding: 40px 0;
  margin: 0;
  background-color: #eee !important;
}

#portfolio{
  padding-bottom: 0px;
}

.gallery-body ol{
	font-size: 15px;
  font-weight: 300;
  line-height: 33px;
}

.gallery-body img, .gallery-body iframe{
	max-width: 90%;
}

.carousel-item img{
  max-width: 100%;
}

.page-footer .row {
  margin: 0;
}

.page-footer .materialize-logo {
  margin-top: 2px;
}

.page-footer p {
  margin: 5px 0;
}

.page-footer h5{
  font-size: .9rem;
    font-weight: 500;
    text-transform: uppercase;
}

.page-footer ul li {
  margin-bottom: 5px;
}

.page-footer ul li a,
.page-footer p {
  color: #aaa;
  font-size: .8rem;
  font-weight: 300;
  margin: 5px 0;
}

.light .header-wrapper .tagline, .page-footer .footer-copyright .header-wrapper .tagline,
.white .header-wrapper .tagline {
  color: #777;
}

.horizontal-half-wrapper .header-background.white + .header-wrapper .tagline {
  color: #777;
}

.white input[type=text],
.white input[type=password],
.white input[type=email],
.white input[type=url],
.white input[type=time],
.white input[type=date],
.white input[type=datetime-local],
.white input[type=tel],
.white input[type=number],
.white input[type=search],
.white textarea.materialize-textarea {
  color: #444;
  border-color: rgba(0, 0, 0, 0.4);
}

.white input[type=text]:focus, .white input[type=text]:focus:not([readonly]),
.white input[type=password]:focus,
.white input[type=password]:focus:not([readonly]),
.white input[type=email]:focus,
.white input[type=email]:focus:not([readonly]),
.white input[type=url]:focus,
.white input[type=url]:focus:not([readonly]),
.white input[type=time]:focus,
.white input[type=time]:focus:not([readonly]),
.white input[type=date]:focus,
.white input[type=date]:focus:not([readonly]),
.white input[type=datetime-local]:focus,
.white input[type=datetime-local]:focus:not([readonly]),
.white input[type=tel]:focus,
.white input[type=tel]:focus:not([readonly]),
.white input[type=number]:focus,
.white input[type=number]:focus:not([readonly]),
.white input[type=search]:focus,
.white input[type=search]:focus:not([readonly]),
.white textarea.materialize-textarea:focus,
.white textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #444;
}

.white input[type=text]:focus + label, .white input[type=text]:focus:not([readonly]) + label,
.white input[type=password]:focus + label,
.white input[type=password]:focus:not([readonly]) + label,
.white input[type=email]:focus + label,
.white input[type=email]:focus:not([readonly]) + label,
.white input[type=url]:focus + label,
.white input[type=url]:focus:not([readonly]) + label,
.white input[type=time]:focus + label,
.white input[type=time]:focus:not([readonly]) + label,
.white input[type=date]:focus + label,
.white input[type=date]:focus:not([readonly]) + label,
.white input[type=datetime-local]:focus + label,
.white input[type=datetime-local]:focus:not([readonly]) + label,
.white input[type=tel]:focus + label,
.white input[type=tel]:focus:not([readonly]) + label,
.white input[type=number]:focus + label,
.white input[type=number]:focus:not([readonly]) + label,
.white input[type=search]:focus + label,
.white input[type=search]:focus:not([readonly]) + label,
.white textarea.materialize-textarea:focus + label,
.white textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #444;
}

.white input[type=text] + label,
.white input[type=password] + label,
.white input[type=email] + label,
.white input[type=url] + label,
.white input[type=time] + label,
.white input[type=date] + label,
.white input[type=datetime-local] + label,
.white input[type=tel] + label,
.white input[type=number] + label,
.white input[type=search] + label,
.white textarea.materialize-textarea + label {
  color: rgba(0, 0, 0, 0.4);
}

@media only screen and (min-width: 993px){
.container {
    width: 80%;
}
}

@media only screen and (max-width: 600px), only screen and (max-device-width: 641px) and (orientation: landscape) {
  .section {
    height: auto;
  }
  .section.full-height .valign-wrapper {
    display: block;
  }
  .google-map {
    height: 100%;
  }
  .header .header-wrapper h1 {
    font-size: 4rem;
  }
}
